<template>
  <label
		class="flex"
		:class="{
			'flex-col': !labelInline,
			'gap-2': labelInline,
		}"
	>
    <div
			v-if="label"
			class="text-gray-500"
			:class="{
				'order-2': labelInline,
			}"
		>
			<span>
				{{ label }}
			</span>
			<span
				v-if="required"
				class="text-red-500"
			>*</span>
		</div>
    <component
			:is="currentTypeConfig.component"
			class="appearance-none mt-1 text-sm text-gray-600 placeholder-gray-400"
			:class="[currentTypeConfig.props?.class, inputProps?.class]"
			:placeholder="placeholder || label"
			v-model.trim="model"
			v-bind="{...currentTypeConfig.props, ...inputProps}"
		/>
  </label>
</template>

<script>
const TextInput = () => import("./inputs/TextInput.vue");
const Toggle = () => import("./inputs/Toggle.vue");

const defaultType = "text";

export default {
	props: {
		value: {
			type: [String, Number, Boolean, Object, Array],
			default: null,
		},
		type: {
			type: String,
			default: defaultType,
		},
		label: {
			type: String,
		},
		labelInline: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
		},
		required: {
			type: Boolean,
			default: false,
		},
		inputProps: {
			type: Object,
			default: null,
		},
	},
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		typeConfig() {
			return {
				text: {
					component: TextInput,
					props: {
						style: "border: 1px solid #e8e8e8",
					}
				},
				toggle: {
					component: Toggle,
					props: {
						style: "border: 1px solid #e8e8e8",
					},
				}
			}
		},
		currentTypeConfig() {
			return this.typeConfig[this.type] ?? this.typeConfig[defaultType];
		},
	},
};
</script>

<style lang="scss" scoped>
</style>