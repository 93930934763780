import mainAxios from "@shared/light-ray/axios";
import { mapGetters } from "vuex";
import {cloneDeep} from "lodash";
const NeoInput = () => import("@/components/input");
const NeoLoader = () => import("@/components/loader");

export default {
    name: "add-media",
    components: {
        NeoInput,
        NeoLoader,
    },
    props: {
        searchqueries: {
            type: Array,
        },
        totalqueries: {
            type: Array,
        },
    },
    data() {
        return {
            isLoading: false,
            initialData: {
                "screenshot": null,
                "screenshot_caption": "",
                "case_id": "",
                "notes": "",
                "customer_notes": "",
                "entity_attributes": [],
                "show_on_graph": false,
                "graph_icon": "",
                "graph_label": "",
                "status": "CONFIRMED",
                "entity_status": "",
                "url": "",
                "title": "",
                "risk_categories": [],
                "monitoring": false,
                "is_adverse": true,
                "key_facts": [],
                "relations": [],
                "output_category_id": "",
                "output_section_id": "",
                "media_type": "passive_media",
                "published_at": null,
                "raw_page_text": "",
                "snippet": "",
                "summary": "",
            },
            media: {},
        };
    },
    computed: {
        ...mapGetters(["getNeoCaseId"])
    },
    mounted() {
        this.media = cloneDeep(this.initialData);
    },
    methods: {
        async saveMedia() {
            if (this.media.title) {
                this.isLoading = true;
                try {
                    this.media.case_id = this.getNeoCaseId;
                    this.media.main_entity_id = this.$route.query.entityId;
                    await mainAxios.post("/service/active-passive-media", this.media);
                    this.$toast.success("Media saved successfully.")
                } catch (error) {
                    this.$toast.error("Some error occured")
                    console.log(error);
                }
                this.isLoading = false;
                this.media = {};
                this.$modal.hide("add-media");
                this.$emit("onAddMedia");
            } else {
                this.$toast.error("Please enter a title");
            }
        },
        beforeOpen() {
            this.isLoading = false;
            this.media = cloneDeep(this.initialData);
        },
        closeModal() {
            this.$modal.hide("add-media");
        },
    },
};
