import osintAxios from "@shared/light-ray/axios/osint-axios";
import {getBackgroundStatus} from "@shared/utils/monitoringTools.js";
import Badge from "@shared/saas-components/badge";
import neoSwitch from "@shared/saas-components/toggle-switch";
import {mapGetters} from "vuex";
import axios from "@/axios";
import NeoBadgeList from "@shared/saas-components/badge-list";
import {DateTime} from "luxon";
import ImagePlaceholder from "@shared/saas-components/image-placeholder";
import CanvasMenu from "@shared/saas-components/canvas-menu";
import {FEATURES} from "@shared/utils/constants";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "@shared/light-ray/assets/svg/check.svg";
import Question from "@shared/light-ray/assets/svg/question.svg";
import CrossRed from "@shared/light-ray/assets/svg/cross-red.svg";

export default {
    name: "search-engine-card",
    components: {
        "neo-badge": Badge,
        neoSwitch,
        NeoBadgeList,
        ImagePlaceholder,
        CanvasMenu,
        confirmed: Check,
        unknown: MinusIcon,
        None: MinusIcon,
        potential: Question,
        irrelevant: CrossRed,
    },
    props: ["data", "onClick", "clickedLinks", "onHandleMarkAsDone", "isDoneLoading", "queryId", "searchedData", "analyzerCount", "currentTab", "autorun", "autoAnalyser", "mainEntity", "riskCategoriesOptions", "currentTreeSection"],
    // props: {
    //     data, 
    //     onClick, clickedLinks, onHandleMarkAsDone, isDoneLoading, queryId, searchedData, analyzerCount, currentTab, autorun, autoAnalyser, 
    //     mainEntity: Array,
    //     individualData: Object,
    // },
    data() {
        return {
            showList: false,
            analysisStart: false,
            dataValue: this.searchedData,
            openDetails: false,
            allCardDetails: {},
        };
    },
    methods: {
        getBackgroundStatus,
        getStatusClass(status) {
            let statusClass = "rounded-full cursor-pointer p-1.5 h-7 w-7"
            if (status == 'potential' || status == 'irrelevant') {
                statusClass = "bg-red-100 " + statusClass
            }
            else if (status == 'unknown' || status == 'None' || !status) {
                statusClass = "bg-gray-300 " + statusClass
            }
            else {
                statusClass = "bg-green-50 " + statusClass
            }
            return statusClass
        },
        closeCanvas(detail) {
            this.openDetails = false
            this.$emit("handleStatusUpdate", detail)
        },
        updateCardStatus(detail) {
            this.$emit("handleStatusUpdate", detail)
        },
        toogleDropdown() {
            this.showList = !this.showList;
        },
        closeDropdown() {
            this.showList = false;
        },
        async selectDropdown(event, detail) {
            this.showList = false;
            let key = event.target.innerText.toUpperCase();
            if (key === 'UNKNOWN') {
                key = ""
            }

            if (detail.status === key || 
                ((!detail.status || detail.status === "UNKNOWN") && !key)
            ) return;
            else {
                let url;
                let payload = {
                    _id: detail._id,
                    case_id: this.getNeoCaseId,
                    entity: detail.title,
                    query_id: this.$route.query.query_id,
                    tool_name: this.$route.query.check_name,
                    status: key,
                    main_entity_id: this.$route.query.entityId,
                    source_name: "search-engine",
                }
                if (detail.card_type === 'media') url = `/service/active-passive-media`;
                else url = `/search-engine/${detail._id}`;
                await axios.put(url, payload);
                this.$toast.success("Successfully Updated");
                detail.status = key
                this.updateCardStatus(detail)
            }
        },
        showCardDetails(detail) {
            this.openDetails = true
            let cardDetails = {
                case_id: this.getNeoCaseId,
                entity: detail.title,
                query_id: this.$route.query.query_id,
                tool_name: this.$route.query.check_name,
                status: detail.status,
                main_entity_id: this.$route.query.entityId,
                source_name: "search-engine",
                relations: detail.relations ?? [],
            }
            this.allCardDetails = {...this.data, ...cardDetails};
        },

        permissionExists(key) {
            return this.getPermissions.find((res) => FEATURES.tools[res] == key);
        },
        async startAnalyzer(cardData) {
            let config = {};
            this.getAnalyzerOptions.forEach((res) => {
                if (this.permissionExists(res.key)) {
                    config[res.payload_name] = res.selected ? res.selected : res.analysis;
                }
            });
            this.analysisStart = true;
            const url = `/case-analysis/${cardData.case_id}/analysis`;
            try {
                await axios.post(url, {
                    record_count: 1,
                    tool: "search-engine-tool",
                    queries: [
                        {
                            query_id: cardData.query_id ? cardData.query_id : this.queryId,
                            doc_ids: [cardData._id],
                        },
                    ],
                    config: config,
                });
                this.startAnalyzerInInterval(cardData._id);
            } catch (err) {
                this.$toast.error("Something went wrong.");
                this.analysisStart = false;
            }
        },
        startAnalyzerInInterval(cardId) {
            this.$emit("handleAnalysisStatus", {id: cardId});
        },
        async showPreview() {
            this.previewItem = this.data;
            const response = await osintAxios.post("/api/v1/search-engine/preview", {
                item: this.isMonitoringTab ? this.data.mongo_doc_id : this.data._id,
                case_id: this.data.case_id,
            });

            if (response.data.status) {
                this.showPreviewModal = true;
                let yourWindow = window.open();
                yourWindow.opener = null;
                yourWindow.location = response.data.preview;
                yourWindow.target = "_blank";
            } else {
                this.$toast.error(response.data.message);
            }
        },
        getResultFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        handleOnClick(link, id) {
            // window.open(link, "_blank", "noopener,noreferrer");
            open(link, "_blank", "noopener,noreferrer");
            this.onClick(link, id);
        },
        visitedClass(data, clickedLinks) {
            return data.visited || clickedLinks.find((ele) => ele.links.includes(data.link)) || (this.getReadOnlyMode && "cursor-not-allowed");
        },
        checkedInput(data, clickedLinks) {
            return data.done || clickedLinks.find((ele) => ele.doneLinks.includes(data.link)) || data.changes_viewed;
        },
        disabledCheck(data, clickedLinks) {
            return data.visited || this.getReadOnlyMode || clickedLinks.find((ele) => ele.links.includes(data.link)) || data.isDoneLoading || this.isDoneLoading;
        },
        async handleMonitoring() {
            // event.stopPropagation();
            this.data.monitoring_status = !this.data.monitoring_status;
            this.$forceUpdate();
            await axios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.data._id,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: this.data._id,
                card_source: this.$route.params.toolName,
                status: this.data.monitoring_status ? "enabled" : "disabled",
            });
        },
        handleMarkAsDone(event, data) {
            if (this.isMonitoringTab) {
                this.onHandleMarkAsDone(event, data._id, data.link, data);
            } else {
                this.onHandleMarkAsDone(event, data._id, data.link, data);
            }
        },
        fetchList(labels) {
            if (labels && labels.length > 0) {
                return labels.map((label) => ({label: label.category}));
            } else return [];
        },
        getSentimentStatusClass(status) {
            let classes = "";
            if (status === "Neutral") classes += "bg-gray-500";
            else if (status === "Positive") classes += "bg-green-500";
            else if (status === "Negative") classes += "bg-red-500";
            return classes;
        },

        checkCategories(item) {
            let risk = this.riskCategoriesOptions.find(v => v.category == item);
            if (risk) {
                return risk.category_type === 'Risk Categories' ? 
                    ((this.data.entity_status === 'Amber') ? 'amberLabel' : 
                        'redLabel') : 
                    'greenLabel';
            }
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getAnalyzerOptions", "getPermissions", "getAutoAnalyze", "getNeoCaseId"]),
        getReadOnlyMode() {
            return this.$store.getters.getReadOnlyMode;
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        showAnalyser() {
            return this.getAnalyzerOptions.filter((res) => res.selected);
        },
        CompletedData() {
            let isNull = this.dataValue?.some((el) => el.analysis_status === "pending" || !el.analysis_status);
            return isNull;
        },
        autoAnalyser() {
            return this.getAutoAnalyze;
        },
    },
    mounted() {
        // this.getCategoriesOption();
    }
};
